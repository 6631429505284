export const ICON_PATHS = {
  'CSD50': require('../../assets/CSDLogo33.png'),
  'CSD10': require('../../assets/CSDLogo33.png'),
  'matic-network': require('../../assets/matic-network.webp'),
  'matic-network': require('../../assets/matic-network.webp'),
  'stellar': require('../../assets/stellar.webp'),
  'bitcoin-cash': require('../../assets/bitcoin-cash.webp'),
  'monero': require('../../assets/monero.webp'),
  'ethereum-classic': require('../../assets/ethereum-classic.webp'),
  'flow': require('../../assets/flow.webp'),
  'dai': require('../../assets/dai.webp'),
  'cosmos': require('../../assets/cosmos.webp'),
  'staked-ether': require('../../assets/staked-ether.webp'),
  'tezos': require('../../assets/tezos.webp'),
  'theta-fuel': require('../../assets/theta-fuel.webp'),
  'hedera-hashgraph': require('../../assets/hedera-hashgraph.webp'),
  'avalanche-2': require('../../assets/avalanche-2.webp'),
  'near': require('../../assets/near.webp'),
  'uniswap': require('../../assets/uniswap.webp'),
  'shiba-inu': require('../../assets/shiba-inu.webp'),
  'vechain': require('../../assets/vechain.webp'),
  'leo-token': require('../../assets/leo-token.webp'),
  'algorand': require('../../assets/algorand.webp'),
  'crypto-com-chain': require('../../assets/crypto-com-chain.webp'),
  'bitcoin': require('../../assets/bitcoin.webp'),
  'ethereum': require('../../assets/ethereum.webp'),
  'ripple': require('../../assets/ripple.webp'),
  'solana': require('../../assets/solana.webp'),
  'dogecoin': require('../../assets/dogecoin.webp'),
  'tether': require('../../assets/tether.webp'),
  'usd-coin': require('../../assets/usd-coin.webp'),
  'compound-usd-coin': require('../../assets/compound-usd-coin.webp'),
  'theta-token': require('../../assets/theta-token.webp'),
  'compound-ether': require('../../assets/compound-ether.webp'),
  'axie-infinity': require('../../assets/axie-infinity.webp'),
  'true-usd': require('../../assets/true-usd.webp'),
  'filecoin': require('../../assets/filecoin.webp'),
  'decentraland': require('../../assets/decentraland.webp'),
  'the-sandbox': require('../../assets/the-sandbox.webp'),
  'apecoin': require('../../assets/apecoin.webp'),
  'kucoin-shares': require('../../assets/kucoin-shares.webp'),
  'internet-computer': require('../../assets/internet-computer.webp'),
  'frax': require('../../assets/frax.webp'),
  'binancecoin': require('../../assets/binancecoin.webp'),
  'cardano': require('../../assets/cardano.webp'),
  'binance-usd': require('../../assets/binance-usd.webp'),
  'okb': require('../../assets/okb.webp'),
  'polkadot': require('../../assets/polkadot.webp'),
  'chain-2': require('../../assets/chain-2.webp'),
  'wrapped-bitcoin': require('../../assets/wrapped-bitcoin.webp'),
  'tron': require('../../assets/tron.webp'),
  'flow': require('../../assets/flow.webp'),
  'aptos': require('../../assets/aptos.webp'),
  'eos': require('../../assets/eos.webp'),
  'evmos': require('../../assets/evmos.webp'),
  'dash': require('../../assets/dash.webp'),
  'fantom': require('../../assets/fantom.webp'),
  'gemini-dollar': require('../../assets/gemini-dollar.webp'),
  'helium': require('../../assets/helium.webp'),
  'klay-token': require('../../assets/klay-token.webp'),
  'maker': require('../../assets/maker.webp'),
  'neo': require('../../assets/neo.webp'),
  'pancakeswap-token': require('../../assets/pancakeswap-token.webp'),
  'paxos-standard': require('../../assets/paxos-standard.webp'),
  'quant-network': require('../../assets/quant-network.webp'),
  'the-graph': require('../../assets/the-graph.webp'),
  'usdd': require('../../assets/usdd.webp'),
  'zcash': require('../../assets/zcash.webp'),
}
