const path = process.env['REACT_APP_S3_BUCKET_MINI_CHARTS'];

const MINI_CHARTS = {
  'CSD50': path + '/CSD50.svg',
  'CSD10': path + '/CSD10.svg',

  // ---------------- none-stables ----------------
  'bitcoin': path + '/bitcoin.svg',
  'ethereum': path + '/ethereum.svg',
  'dash': path + '/dash.svg',
  'aptos': path + '/aptos.svg',
  'zcash': path + '/zcash.svg',
  'ripple': path + '/ripple.svg',
  'solana': path + '/solana.svg',
  'dogecoin': path + '/dogecoin.svg',
  'theta-token': path + '/theta-token.svg',
  'axie-infinity': path + '/axie-infinity.svg',
  'filecoin': path + '/filecoin.svg',
  'decentraland': path + '/decentraland.svg',
  'the-sandbox': path + '/the-sandbox.svg',
  'apecoin': path + '/apecoin.svg',
  'kucoin-shares': path + '/kucoin-shares.svg',
  'internet-computer': path + '/internet-computer.svg',
  'maker': path + '/maker.svg',
  'binancecoin': path + '/binancecoin.svg',
  'quant-network': path + '/quant-network.svg',
  'cardano': path + '/cardano.svg',
  'okb': path + '/okb.svg',
  'polkadot': path + '/polkadot.svg',
  'chain-2': path + '/chain-2.svg',
  'wrapped-bitcoin': path + '/wrapped-bitcoin.svg',
  'tron': path + '/tron.svg',
  'bitcoin-cash': path + '/bitcoin-cash.svg',
  'monero': path + '/monero.svg',
  'ethereum-classic': path + '/ethereum-classic.svg',
  'eos': path + '/eos.svg',
  'klay-token': path + '/klay-token.svg',
  'pancakeswap-token': path + '/pancakeswap-token.svg',
  'neo': path + '/neo.svg',
  'helium': path + '/helium.svg',
  'evmos': path + '/evmos.svg',
  'the-graph': path + '/the-graph.svg',
  'fantom': path + '/fantom.svg',
  'theta-fuel': path + '/theta-fuel.svg',
  'tezos': path + '/tezos.svg',
  'hedera-hashgraph': path + '/hedera-hashgraph.svg',
  'flow': path + '/flow.svg',
  'near': path + '/near.svg',
  'vechain': path + '/vechain.svg',
  'algorand': path + '/algorand.svg',
  'stellar': path + '/stellar.svg',
  'cosmos': path + '/cosmos.svg',
  'leo-token': path + '/leo-token.svg',
  'avalanche-2': path + '/avalanche-2.svg',
  'uniswap': path + '/uniswap.svg',
  'shiba-inu': path + '/shiba-inu.svg',
  'matic-network': path + '/matic-network.svg',

  // ---------------- stables ----------------
  'tether': path + '/tether.svg',
  'usd-coin': path + '/usd-coin.svg',
  'binance-usd': path + '/binance-usd.svg',
  'dai': path + '/dai.svg',
  'frax': path + '/frax.svg',
  'true-usd': path + '/true-usd.svg',
  'usdd': path + '/usdd.svg',
  'gemini-dollar': path + '/gemini-dollar.svg',
  'paxos-standard': path + '/paxos-standard.svg',
  'compound-usd-coin': path + '/compound-usd-coin.svg'
}

module.exports = { MINI_CHARTS };
