const WEEK_DICT = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday"
};

const DAY_DICT = {
  "Monday": "Monday",
  "Tuesday": "Tuesday",
  "Wednesday": "Wednesday",
  "Thursday": "Thursday",
  "Friday": "Friday",
  "Saturday": "Saturday",
  "Sunday": "Sunday",
};

const MONTH_DICT = {
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
  "10": 10,
  "11": 11,
  "12": 12,
  "13": 13,
  "14": 14,
  "15": 15,
  "16": 16,
  "17": 17,
  "18": 18,
  "19": 19,
  "20": 20,
  "21": 21,
  "22": 22,
  "23": 23,
  "24": 24,
  "25": 25,
  "26": 26,
  "27": 27,
  "28": 28,
  "29": 29,
  "30": 30,
  "31": 31
};

const MONTH_DATA_DICT = {
  "1": 0,
  "2": 0,
  "3": 0,
  "4": 0,
  "5": 0,
  "6": 0,
  "7": 0,
  "8": 0,
  "9": 0,
  "10": 0,
  "11": 0,
  "12": 0,
  "13": 0,
  "14": 0,
  "15": 0,
  "16": 0,
  "17": 0,
  "18": 0,
  "19": 0,
  "20": 0,
  "21": 0,
  "22": 0,
  "23": 0,
  "24": 0,
  "25": 0,
  "26": 0,
  "27": 0,
  "28": 0,
  "29": 0,
  "30": 0,
  "31": 0,
  "11_count": 0,
  "11_avg": 0,
  "6_count": 0,
  "6_avg": 0,
  "31_count": 0,
  "31_avg": 0,
  "27_count": 0,
  "27_avg": 0,
  "23_count": 0,
  "23_avg": 0,
  "19_count": 0,
  "19_avg": 0,
  "14_count": 0,
  "14_avg": 0,
  "9_count": 0,
  "9_avg": 0,
  "5_count": 0,
  "5_avg": 0,
  "1_count": 0,
  "1_avg": 0,
  "26_count": 0,
  "26_avg": 0,
  "22_count": 0,
  "22_avg": 0,
  "18_count": 0,
  "18_avg": 0,
  "12_count": 0,
  "12_avg": 0,
  "8_count": 0,
  "8_avg": 0,
  "4_count": 0,
  "4_avg": 0,
  "25_count": 0,
  "25_avg": 0,
  "21_count": 0,
  "21_avg": 0,
  "13_count": 0,
  "13_avg": 0,
  "7_count": 0,
  "7_avg": 0,
  "30_count": 0,
  "30_avg": 0,
  "16_count": 0,
  "16_avg": 0,
  "3_count": 0,
  "3_avg": 0,
  "29_count": 0,
  "29_avg": 0,
  "15_count": 0,
  "15_avg": 0,
  "2_count": 0,
  "2_avg": 0,
  "24_count": 0,
  "24_avg": 0,
  "20_count": 0,
  "20_avg": 0,
  "28_count": 0,
  "28_avg": 0,
  "10_count": 0,
  "10_avg": 0,
  "17_count": 0,
  "17_avg": 0
};

const DAY_DATA_DICT = {
  "Monday": 0,
  "Monday_count": 0,
  "Monday_avg": 0,
  "Sunday": 0,
  "Sunday_count": 0,
  "Sunday_avg": 0,
  "Saturday": 0,
  "Saturday_count": 0,
  "Saturday_avg": 0,
  "Friday": 0,
  "Friday_count": 0,
  "Friday_avg": 0,
  "Thursday": 0,
  "Thursday_count": 0,
  "Thursday_avg": 0,
  "Wednesday": 0,
  "Wednesday_count": 0,
  "Wednesday_avg": 0,
  "Tuesday": 0,
  "Tuesday_count": 0,
  "Tuesday_avg": 0
};

const HOUR_DICT = {
  "0": 0,
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
  "10": 10,
  "11": 11,
  "12": 12,
  "13": 13,
  "14": 14,
  "15": 15,
  "16": 16,
  "17": 17,
  "18": 18,
  "19": 19,
  "20": 20,
  "21": 21,
  "22": 22,
  "23": 23
};

const HOUR_DATA_DICT = {
  "0": 0,
  "1": 0,
  "2": 0,
  "3": 0,
  "4": 0,
  "5": 0,
  "6": 0,
  "7": 0,
  "8": 0,
  "9": 0,
  "10": 0,
  "11": 0,
  "12": 0,
  "13": 0,
  "14": 0,
  "15": 0,
  "16": 0,
  "17": 0,
  "18": 0,
  "19": 0,
  "20": 0,
  "21": 0,
  "22": 0,
  "23": 0,
  "7_count": 0,
  "7_avg": 0,
  "0_count": 0,
  "0_avg": 0,
  "14_count": 0,
  "14_avg": 0,
  "17_count": 0,
  "17_avg": 0,
  "13_count": 0,
  "13_avg": 0,
  "6_count": 0,
  "6_avg": 0,
  "21_count": 0,
  "21_avg": 0,
  "2_count": 0,
  "2_avg": 0,
  "19_count": 0,
  "19_avg": 0,
  "23_count": 0,
  "23_avg": 0,
  "12_count": 0,
  "12_avg": 0,
  "1_count": 0,
  "1_avg": 0,
  "16_count": 0,
  "16_avg": 0,
  "22_count": 0,
  "22_avg": 0,
  "9_count": 0,
  "9_avg": 0,
  "10_count": 0,
  "10_avg": 0,
  "4_count": 0,
  "4_avg": 0,
  "3_count": 0,
  "3_avg": 0,
  "5_count": 0,
  "5_avg": 0,
  "18_count": 0,
  "18_avg": 0,
  "15_count": 0,
  "15_avg": 0,
  "11_count": 0,
  "11_avg": 0,
  "8_count": 0,
  "8_avg": 0,
  "20_count": 0,
  "20_avg": 0
};

module.exports = {
  MONTH_DATA_DICT,
  MONTH_DICT,
  DAY_DICT,
  WEEK_DICT,
  DAY_DATA_DICT,
  HOUR_DATA_DICT,
  HOUR_DICT,
}